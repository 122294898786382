import type { CheckedState } from "@radix-ui/react-checkbox";
import {
  Button,
  Checkbox,
  MiniTag,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  SortableList,
} from "@themis/ui";
import type { ReactElement } from "react";
import { useCallback, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { TbColumns3 } from "react-icons/tb";
import { useIntl } from "react-intl";

import type { RecordType, UpdateLayoutMutationRequest } from "@/api/gen/models";
import { useUpdateLayout } from "@/api/queries/layouts/use-update-layout";
import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { useOpen } from "@/hooks/use-open/use-open";
import { usePermissions } from "@/hooks/use-permissions";

import { CustomColumnDialog } from "../custom-column-dialog/custom-column-dialog";

/**
 * For now those column have their order locked,
 * might come from the backend in the future
 */
const sortedLockedColumns = ["name"];

interface ColumnOption {
  label: string;
  id: number;
  editable: boolean;
  orderEditable: boolean;
  selected: boolean;
}

interface ColumnsSelectProps {
  companyId: number;
  recordType: RecordType;
  workspaceId: number;
}

export function ColumnsSelect({
  companyId,
  recordType,
  workspaceId,
}: ColumnsSelectProps): ReactElement | null {
  const { close, isOpen, toggle } = useOpen();
  const customColumnDialog = useOpen();
  const intl = useIntl();
  const layoutType = "table";

  const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([]);

  const { add_remove_columns: canManageColumns } = usePermissions({
    domain: "module",
    module: recordType,
  });
  const { layout, layoutFields } = useLayoutFields({
    companyId,
    recordType,
    workspaceId,
    layoutType,
  });

  const updateLayout = useUpdateLayout({
    companyId,
    id: layout?.id,
    recordType,
    workspaceId,
  });

  const buttonColor = isOpen ? "active" : "transparent";

  const numberOfSelectedColumns = columnOptions.filter(
    (_columnOptions) => _columnOptions.selected,
  ).length;

  const initColumnOptions = useCallback(
    (fields: FieldConfigurationWithLayout[] = []) => {
      setColumnOptions(
        fields.map((column) => ({
          label: column.field.display_name,
          id: column.layoutField.id,
          editable: column.field.custom,
          selected: column.layoutField.visible,
          orderEditable: !sortedLockedColumns.includes(column.field.name),
        })),
      );
    },
    [],
  );

  useEffect(() => {
    initColumnOptions(layoutFields);
  }, [layoutFields, initColumnOptions]);

  const handleAddCustom = () => {
    close();
    customColumnDialog.open();
  };

  const handleApply = async () => {
    if (!layout) {
      return;
    }

    const orderedFields = columnOptions.reduce(
      (
        acc: UpdateLayoutMutationRequest["layout"]["ordered_fields"],
        column,
      ) => {
        acc?.push({
          id: column.id,
          visible: column.selected,
        });

        return acc;
      },
      [],
    );

    await updateLayout.mutateAsync({
      layout: {
        ordered_fields: orderedFields,
      },
    });

    close();
  };

  const handleCancel = () => {
    // reset columns to initial state
    initColumnOptions(layoutFields);
    close();
  };

  const handleCheckedChange = (optionId: number, checked: CheckedState) => {
    setColumnOptions((previousOptions) =>
      previousOptions.map((option) => {
        if (option.id === optionId) {
          option.selected = Boolean(checked);
        }

        return option;
      }),
    );
  };

  const handleReorder = (_options: ColumnOption[]) => {
    setColumnOptions(_options);
  };

  if (!canManageColumns) {
    return null;
  }

  return (
    <>
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button LeftIcon={TbColumns3} color={buttonColor} onClick={toggle}>
            {intl.formatMessage({
              defaultMessage: "Columns",
              description: "Custom columns select trigger text",
            })}
            <MiniTag
              className="tw-min-w-5"
              size="sm"
              variant="default"
              color="black"
            >
              {numberOfSelectedColumns}
            </MiniTag>
          </Button>
        </PopoverTrigger>

        <PopoverContent align="end" onEscapeKeyDown={handleCancel}>
          <div className="tw-box-border tw-flex tw-min-w-72 tw-max-w-80 tw-flex-col tw-gap-1 tw-font-sans">
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-pb-2 tw-pt-4">
              <h3 className="tw-font-semibold tw-text-neutral-500">
                {intl.formatMessage({
                  defaultMessage: "Column Settings",
                  description: "Custom columns popover title",
                })}
              </h3>
            </div>
          </div>
          <ScrollArea className="tw-flex tw-max-h-60 tw-flex-col">
            <SortableList
              classes={{
                item: "tw-px-4 tw-py-1.5 tw-min-h-7 tw-bg-white tw-bg-opacity-85 tw-backdrop-blur-[1px]",
              }}
              items={columnOptions}
              getIsItemDisabled={(option) => !option.orderEditable}
              renderItem={(option) => (
                <label
                  className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-2"
                  key={option.id}
                >
                  <Checkbox
                    size="md"
                    color="primary"
                    checked={option.selected}
                    disabled={!option.editable}
                    onCheckedChange={(checked) =>
                      handleCheckedChange(option.id, checked)
                    }
                  />
                  <span className="tw-select-none tw-text-sm tw-font-medium tw-text-neutral-500">
                    {option.label}
                  </span>
                </label>
              )}
              onReorder={handleReorder}
            />
          </ScrollArea>
          <div className="tw-h-px tw-bg-primary-50" />
          <div className="tw-bg-primary-25 tw-px-1.5 tw-py-1">
            <Button
              color="transparent"
              LeftIcon={PiPlusBold}
              onClick={handleAddCustom}
            >
              {intl.formatMessage({
                defaultMessage: "Add Custom Column",
                description: "Add Custom Column button text",
              })}
            </Button>
          </div>
          <div className="tw-h-px tw-bg-primary-50" />
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-2 tw-px-4 tw-py-3">
            <Button loading={updateLayout.isPending} onClick={handleApply}>
              {intl.formatMessage({
                defaultMessage: "Apply",
                description: "Apply button text",
              })}
            </Button>
            <Button color="tertiary" onClick={handleCancel}>
              {intl.formatMessage({
                defaultMessage: "Cancel",
                description: "Cancel button text",
              })}
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      {customColumnDialog.isOpen && (
        <CustomColumnDialog
          companyId={companyId}
          open
          recordType={recordType}
          onOpenChange={customColumnDialog.toggle}
        />
      )}
    </>
  );
}
