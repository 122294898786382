import { zodResolver } from "@hookform/resolvers/zod";
import type { SelectItem } from "@themis/ui";
import {
  Avatar,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MiniTag,
  Select,
  TextArea,
  TextInput,
} from "@themis/ui";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import type { Account, Contact } from "@/api";
import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { AdditionalDetails } from "@/components/additional-details/additional-details";
import { getDynamicDefaultFormValues } from "@/components/dynamic-field/utils/get-dynamic-default-form-values/get-dynamic-default-form-values";
import { ImageFormField } from "@/components/form-fields/image-form-field/image-form-field";
import { UserSelectFormField } from "@/components/form-fields/user-select-form-field/user-select-form-field";
import { WebsiteField } from "@/features/accounts/components/AccountInfo/website-field";
import type { ACCOUNT_ID_PARAM } from "@/features/accounts/pages";
import { useAccountTypeFieldOptions } from "@/hooks/use-account-type-field-options";
import { useCountryStateCity } from "@/hooks/useCountryStateCity";

import { CreateAccountTypeDialog } from "../create-account-type-dialog";
import type { AccountInfoSchema } from "./use-validation-schema";
import { useValidationSchema } from "./use-validation-schema";

interface AccountInfoFormProps {
  contacts?: Contact[];
  customFields: FieldConfigurationWithLayout[];
  defaultValues?: Account;
  onSubmit: (values: AccountInfoSchema) => void;
}

// todo: fix unstable components
function AccountInfoForm({
  contacts = [],
  customFields,
  defaultValues,
  onSubmit,
}: AccountInfoFormProps) {
  const { formatMessage } = useIntl();

  const { accountsTypeField, options: accountTypes } =
    useAccountTypeFieldOptions();

  const accountTypesSelectOptions: SelectItem[] = (accountTypes || []).map(
    ({ value, key }, index) => ({
      label: value,
      value: key,
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: ({ label }) => <MiniTag colorIndex={index}>{label}</MiniTag>,
    }),
  );

  const { accountId } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();

  function getMainContact(mainContactId?: number | null) {
    if (!mainContactId) {
      return;
    }

    return contacts.find(({ id }) => id === mainContactId);
  }

  const mainContact = getMainContact(defaultValues?.main_contact_id);

  function getFormDefaultValues(values?: Account) {
    return {
      name: values?.name || "",
      description: values?.description || "",
      mailing_city: values?.mailing_city || "",
      account_types: values?.account_types || [],
      logo: {
        url: values?.logo?.file_url || "",
        signedId: values?.logo?.signed_id || "",
      },
      mailing_postal_code: values?.mailing_postal_code || "",
      legal_name: values?.legal_name || "",
      website: values?.website || "",
      phone: values?.phone || "",
      mailing_street: values?.mailing_street || "",
      mailing_state: values?.mailing_state || "",
      mailing_country: values?.mailing_country || "",
      account_owner_id: values?.account_owner_id || undefined,
      main_contact_id: values?.main_contact_id || undefined,
      mainContactPhone: mainContact?.phone || "",
      mainContactEmail: mainContact?.email || "",
      customFields: getDynamicDefaultFormValues(
        customFields,
        values?.custom_fields,
      ),
    };
  }

  const formSchema = useValidationSchema(customFields);

  const form = useForm<AccountInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: getFormDefaultValues(defaultValues),
  });

  useEffect(() => {
    form.reset(getFormDefaultValues(defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const { countries, states } = useCountryStateCity(
    form.watch("mailing_country"),
  );

  const countryOptions = countries.map(({ name, isoCode }) => ({
    label: name,
    value: isoCode,
  }));

  const stateOptions = states.map(({ name, isoCode }) => ({
    label: name,
    value: isoCode,
  }));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  return (
    <Form {...form}>
      <form
        noValidate
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{formatMessage({ defaultMessage: "Name" })}</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="legal_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Legal Entity Name" })}
              </FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="account_types"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Account Type(s)" })}
              </FormLabel>
              <FormControl>
                <Select
                  multiple
                  error={!!fieldState.error}
                  title={formatMessage({
                    defaultMessage: "Select Account type(s)",
                  })}
                  items={accountTypesSelectOptions}
                  selected={field.value}
                  sort="asc"
                  onSelect={field.onChange}
                  createNewItem={{
                    selectItemLabel: formatMessage({
                      defaultMessage: "Create New Account Type",
                    }),
                    // eslint-disable-next-line react/no-unstable-nested-components
                    createSelectItemComponent: (onClose, onCreate) => (
                      <CreateAccountTypeDialog
                        accountTypeFieldConfigId={Number(accountsTypeField?.id)}
                        onClose={onClose}
                        onCreate={onCreate}
                      />
                    ),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <WebsiteField form={form} />
        <ImageFormField
          control={form.control}
          name="logo"
          label={formatMessage({ defaultMessage: "Logo" })}
          placeholder={formatMessage({
            defaultMessage: "Logo will be displayed here.",
          })}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Company Phone" })}
              </FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Description" })}
              </FormLabel>
              <FormControl>
                <TextArea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_street"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Street" })}
              </FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Country" })}
              </FormLabel>
              <FormControl>
                <Select
                  searchable
                  items={countryOptions}
                  selected={field.value}
                  onSelect={(value) => {
                    // Clear the mailing_state field if the country changes.
                    if (value !== form.getValues("mailing_country")) {
                      form.setValue("mailing_state", "");
                    }

                    field.onChange(value);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_state"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "State" })}
              </FormLabel>
              <FormControl>
                <Select
                  searchable
                  readOnly={Boolean(!stateOptions.length)}
                  items={stateOptions}
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{formatMessage({ defaultMessage: "City" })}</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_postal_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {formatMessage({ defaultMessage: "Postal Code" })}
              </FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <UserSelectFormField
          control={form.control}
          label={formatMessage({ defaultMessage: "Account Owner" })}
          name="account_owner_id"
        />
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-2">
          <h2 className="tw-pt-5 tw-text-lg tw-font-semibold tw-text-neutral-500">
            {formatMessage({ defaultMessage: "Main Contact" })}
          </h2>
          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3">
            <FormField
              control={form.control}
              name="main_contact_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {formatMessage({ defaultMessage: "Main Contact" })}
                  </FormLabel>
                  <FormControl>
                    <Select
                      searchable
                      items={contacts.map(({ id, full_name, initials }) => ({
                        label: full_name,
                        value: String(id),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        Component: () => (
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Avatar size="md" colorIndex={id}>
                              {initials}
                            </Avatar>
                            {full_name}
                          </div>
                        ),
                      }))}
                      selected={field.value ? String(field.value) : undefined}
                      onSelect={(value) => {
                        field.onChange(Number(value));

                        const newMainContact = getMainContact(Number(value));

                        form.setValue(
                          "mainContactPhone",
                          newMainContact?.phone || "",
                        );

                        form.setValue(
                          "mainContactEmail",
                          newMainContact?.email || "",
                        );
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mainContactPhone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {formatMessage({ defaultMessage: "Phone" })}
                  </FormLabel>
                  <FormControl>
                    <TextInput readOnly {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mainContactEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {formatMessage({ defaultMessage: "Email" })}
                  </FormLabel>
                  <FormControl>
                    <TextInput readOnly {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {customFields.length > 0 && (
          <AdditionalDetails
            customFields={customFields}
            control={form.control}
          />
        )}

        <Button
          className="tw-col-span-2 tw-mt-5 tw-place-self-end"
          type="submit"
        >
          {formatMessage({ defaultMessage: "Save Account Info" })}
        </Button>
      </form>
    </Form>
  );
}

export { AccountInfoForm, type AccountInfoSchema };
