import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import type { MainStore } from "../Main";
import type { UserModulePermissions } from "../types/user-permissions-types";

export default class UserPermissionsStore {
  mainStore: MainStore;

  permissions: Partial<UserModulePermissions> = {};
  loadingWorkspacePermissions = true;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      permissions: observable,
      loadingWorkspacePermissions: observable,

      setLoadingWorkspacePermissions: action,
      setPermission: action,
      setPermissions: action,
      resetPermissions: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'domain' implicitly has an 'any' type.
  async index(domain, companyID, workspaceID, identifier) {
    try {
      if (domain === "workspace") {
        this.setLoadingWorkspacePermissions(true);
      }
      const params = {
        workspace_id: workspaceID,
        domain,
        identifier,
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data }: { data: any } = await legacyApi({
        method: "GET",
        url: `/api/react/companies/${companyID}/user_permissions`,
        headers: this.mainStore.getHeaders(),
        params,
      });
      this.setPermissions({ ...this.permissions, ...data.result });
      if (domain === "workspace") {
        this.setLoadingWorkspacePermissions(false);
      }
      return data.result;
    } catch (error) {
      window.console.log(`"Permissions#Index" error ${error}`);
      return false;
    }
  }

  get canManageSectionPermissions() {
    return Boolean(
      this.permissions.section_permissions &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canRemoveFolders() {
    return Boolean(
      this.permissions.folder_management &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canDeleteCompany() {
    return Boolean(this.permissions.delete_company);
  }

  get canManageSettings() {
    return Boolean(
      this.permissions.settings &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canManageAccounts() {
    return Boolean(
      this.permissions.account_management &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canSeeNotifications() {
    return Boolean(
      this.permissions.notification_rules &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canManageTags() {
    return Boolean(
      this.permissions.add_remove_tags &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canDeleteRecords() {
    return Boolean(
      this.permissions.delete_records &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canManageColumns() {
    return Boolean(
      this.permissions.add_remove_columns &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canManageSections() {
    return Boolean(
      this.permissions.add_remove_sections &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canPublishOrLockRecords() {
    return Boolean(
      this.permissions.publish_lock_records &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canUnpublishOrUnlockRecords() {
    return Boolean(
      this.permissions.unlock_records &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canAddComments() {
    return Boolean(
      this.permissions.add_comments &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  /**
   * Only to be used when we don't have a permission for specific functionality
   */
  get hasModuleWriteAccess() {
    return Boolean(
      this.permissions.write_access &&
        this.mainStore.workspaces.isCurrentWorkspaceActive,
    );
  }

  get canAddRemoveWorkspaces() {
    return Boolean(this.permissions.add_remove_workspaces);
  }

  get canViewReports() {
    return Boolean(this.permissions.reports);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
  setPermission(key, value) {
    this.permissions = { ...this.permissions, [key]: value };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPermissions(value) {
    this.permissions = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'loading' implicitly has an 'any' type.
  setLoadingWorkspacePermissions(loading) {
    this.loadingWorkspacePermissions = loading;
  }

  resetPermissions() {
    this.permissions = {};
  }

  cleanup() {
    this.resetPermissions();
  }
}
