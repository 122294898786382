import {
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { PiDotsThreeBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { Comment, ListCommentsPathParams } from "@/api";
import { useDeleteComment } from "@/api/queries/comments/useDeleteComment";
import { useUpdateComment } from "@/api/queries/comments/useUpdateComment";
import { useCurrentUser } from "@/api/queries/users";
import { selectUser, useUsers } from "@/api/queries/users/use-users";
import { Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { useOpen } from "@/hooks/use-open/use-open";
import { usePermissions } from "@/hooks/use-permissions";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { ConfirmPopup } from "../../confirm-popup";
import Spinner from "../../table/shared/Spinner";
import { getTaggedUserIds } from "./getTaggedUserIds";
import { useCopyCommentLink } from "./useCopyCommentLink";

interface CommentHeaderProps {
  comment: Comment;
  detailsPagePath: string;
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
  onStartEditing: () => void;
}

export function CommentHeader({
  comment,
  detailsPagePath,
  recordId,
  recordType,
  onStartEditing,
}: CommentHeaderProps) {
  const workspaceId = useRouteWorkspaceId();
  const { formatMessage } = useIntl();
  const { mutate: updateComment } = useUpdateComment({
    recordType,
    recordId,
  });
  const { mutate: deleteComment } = useDeleteComment({
    recordType,
    recordId,
  });
  const { isOpen, onIsOpenChange, open } = useOpen();

  const { data: author } = useUsers(workspaceId, {
    select: selectUser(comment.author_id ?? -1),
  });

  const { data: currentUser } = useCurrentUser();
  const isAuthor = currentUser?.id === comment.author_id;

  const workspacePermissions = usePermissions({
    domain: "workspace",
  });

  const taggedUserIds = getTaggedUserIds(comment.content);
  const isUserTagged =
    !!currentUser?.id && taggedUserIds.includes(currentUser?.id);

  const canResolveComment =
    isAuthor || workspacePermissions.manage_comments || isUserTagged;

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(comment.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  const handleCopyLink = useCopyCommentLink({
    basePath: detailsPagePath,
    commentId: comment.id,
    recordId,
  });

  const handleResolveComment = (checked: boolean) => {
    updateComment({
      commentId: comment.id,
      data: {
        comment: {
          resolved: checked,
        },
      },
    });
  };

  return (
    <Flex justifySpaceBetween>
      {author ? (
        <Flex alignCenter columnGap={8}>
          <UsersCircle
            initials={author?.initials}
            iconColorIndex={author?.icon_color_index}
          />
          <Flex column>
            <Typography
              label={author?.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </Flex>
      ) : (
        <Spinner />
      )}
      <Flex alignCenter columnGap={6}>
        {canResolveComment && (
          <Checkbox
            size="md"
            checked={comment.resolved}
            onCheckedChange={handleResolveComment}
          />
        )}
        <DropdownMenu>
          <DropdownMenuTrigger>
            <PiDotsThreeBold className="tw-text-xl" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {isAuthor && !comment.resolved && (
              <DropdownMenuItem onSelect={onStartEditing}>
                {formatMessage({ defaultMessage: "Edit" })}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onSelect={handleCopyLink}>
              {formatMessage({ defaultMessage: "Copy link" })}
            </DropdownMenuItem>
            {isAuthor && (
              <DropdownMenuItem onSelect={open}>
                {formatMessage({ defaultMessage: "Delete" })}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <ConfirmPopup
          title={formatMessage({ defaultMessage: "Delete comment" })}
          content={formatMessage({
            defaultMessage:
              "Are you sure you wish to delete this comment? Actions are non-reversible",
          })}
          showPopup={isOpen}
          setShowPopup={onIsOpenChange}
          onConfirmClick={() => deleteComment(comment.id)}
        />
      </Flex>
    </Flex>
  );
}
