import type { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import type { Account } from "@/api/gen/models";
import { useCompany } from "@/api/queries/companies";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { CommentsCell } from "@/components/comments-sidebar/comments-cell";
import { getDynamicTableColumns } from "@/utils/table/get-dynamic-table-columns";

import { AccountTypeCellTooltip } from "../components/account-type-cell-tooltip";
import { AccountTypesTags } from "../components/account-type-tags/account-types-tags";
import { AccountActionsCell } from "../components/AccountsTable/AccountActionsCell";
import { AccountContactCell } from "../components/AccountsTable/AccountContactCell";
import { ActiveTag } from "../components/ActiveTag";
import { ACCOUNT_DETAILS_PATH } from "../pages";

interface UseAccountsTableColumnsParams {
  workspaceId: number;
}

export function useAccountsTableColumns({
  workspaceId,
}: UseAccountsTableColumnsParams) {
  const { formatMessage } = useIntl();

  const { data: companyResult } = useCompany("current");

  const {
    layoutFields = [],
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useLayoutFields({
    companyId: Number(companyResult?.data.company.id),
    recordType: "accounts",
    workspaceId,
    layoutType: "table",
  });

  const columnOverrides: Partial<Record<keyof Account, ColDef<Account>>> =
    useMemo(
      () => ({
        name: {
          editable: false,
          minWidth: 250,
          cellRenderer: "identifierCellRenderer",
          cellRendererParams: (props: CustomCellRendererProps) => {
            const accountId = props.data.id;
            return {
              to: generatePath(ACCOUNT_DETAILS_PATH, {
                workspace_id: workspaceId,
                accountId,
              }),
              ...props,
            };
          },
        },
        account_types: {
          tooltipField: "account_types",
          tooltipComponent: AccountTypeCellTooltip,
          valueFormatter: (params: ValueFormatterParams) =>
            params.value?.length ? params.value[0] : "",
          cellRenderer: (
            props: CustomCellRendererProps<Account, Account["account_types"]>,
          ) => (
            <AccountTypesTags
              isTableTooltip
              accountTypes={props.value || []}
              maxShown={2}
            />
          ),
          cellStyle: () => ({ paddingLeft: "8px" }),
          minWidth: 300,
        },
        status: {
          cellStyle: () => ({ paddingLeft: "8px" }),
          cellRenderer: (props: CustomCellRendererProps) => (
            <ActiveTag isActive={props.value === "active"} />
          ),
        },
        parent: {
          cellRenderer: "textRenderCell",
          cellRendererParams: (props: CustomCellRendererProps<Account>) => {
            const value: string =
              props.value?.name || formatMessage({ defaultMessage: "N/A" });
            return { value };
          },
          valueFormatter: ({ value }) =>
            value?.name || formatMessage({ defaultMessage: "N/A" }),
        },
        main_contact: {
          cellStyle: () => ({ paddingLeft: "8px" }),
          cellRenderer: (props: CustomCellRendererProps) =>
            props.value ? (
              <AccountContactCell contact={props.value} display="name" />
            ) : null,
        },
      }),
      [formatMessage, workspaceId],
    );

  const columns = useMemo(
    () =>
      getDynamicTableColumns<Account>({
        layoutFields,
        columnOverrides,
        additionalColumns: [
          {
            order: 0,
            headerName: "",
            minWidth: 60,
            width: 60,
            sortable: false,
            resizable: false,
            cellStyle: () => ({ justifyContent: "center" }),
            cellRenderer: (props: CustomCellRendererProps) => (
              <CommentsCell
                commentsCount={props.data.comments_count}
                recordId={props.data.id}
                recordType="accounts"
              />
            ),
          },
          {
            headerName: "",
            minWidth: 40,
            width: 40,
            sortable: false,
            resizable: false,
            pinned: "right" as const,
            cellStyle: () => ({ justifyContent: "center" }),
            cellRenderer: (props: CustomCellRendererProps) => (
              <AccountActionsCell accountId={props.data.id} />
            ),
          },
        ],
      }),
    [columnOverrides, layoutFields],
  );

  return {
    columns,
    isColumnsPending,
    isColumnsError,
  };
}
