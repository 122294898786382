import { keyBy } from "lodash";
import { useMemo } from "react";

import type { LayoutLayoutType, RecordType } from "@/api/gen/models";
import { selectLayout, useLayouts } from "@/api/queries/layouts/use-layouts";

import { useFields } from "../../queries/fields/use-fields";
import type { FieldConfigurationWithLayout } from "./field-configuration-with-layout";

interface UseLayoutFieldsOptions {
  companyId: number | undefined;
  layoutType: LayoutLayoutType;
  recordType: RecordType;
  workspaceId: number;
}

export function useLayoutFields({
  companyId,
  layoutType,
  recordType,
  workspaceId,
}: UseLayoutFieldsOptions) {
  const layout = useLayouts(
    { companyId, recordType, workspaceId },
    { select: selectLayout(layoutType) },
  );

  const configs = useFields(
    { companyId, recordType },
    { select: (data) => keyBy(data, "id") },
  );

  const layoutFields: FieldConfigurationWithLayout[] | undefined =
    useMemo(() => {
      if (!layout.data || !configs.data) {
        return undefined;
      }

      return layout.data.layout_fields
        .map((layoutField) => {
          const fieldConfiguration = configs.data[layoutField.field_id];

          return {
            layoutField,
            field: fieldConfiguration,
          };
        })
        .filter(({ field }) => field !== undefined);
    }, [configs.data, layout.data]);

  const customFields = useMemo(
    () => layoutFields?.filter(({ field }) => field?.custom),
    [layoutFields],
  );

  return {
    layout: layout.data,
    customFields,
    layoutFields,
    isSuccess: layout.isSuccess && configs.isSuccess,
    isError: layout.isError || configs.isError,
    isPending: layout.isPending || configs.isPending,
  };
}
